.bg-dark {
    background-color: #343a40;
}

.text-light {
    color: #f8f9fa !important;
}

.menu-card {
    background-color: #495057;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: none;
    border-radius: 10px;
    overflow: hidden;
}

.menu-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.card-text {
    font-size: 1rem;
}

.min-vh-100 {
    min-height: 100vh;
}

.container {
    max-width: 1200px;
}

.alert {
    max-width: 600px;
    margin: 0 auto 20px;
}

.list-group-item {
    background-color: #495057;
    color: #f8f9fa;
    border: none;
    border-radius: 5px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 10px;
}

.list-group-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.alert {
    max-width: 600px;
    margin: 0 auto 20px;
}

.modal-content {
    background-color: #495057;
    color: #f8f9fa;
}

.modal-header, .modal-footer {
    border-color: #343a40;
}

.bg-light {
    background-color: #f8f9fa !important;
}