.admin-dashboard {
    padding: 20px;
    min-height: 100vh;
    background-color: #212529; /* Dark theme */
}

.card {
    border-radius: 8px;
    border: none;
    margin-bottom: 20px;
    transition: transform 0.2s ease-in-out;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.alert {
    margin-top: 1rem;
}

.list-group-item {
    background-color: transparent;
    border: none;
}

.badge {
    font-size: 1rem;
}

canvas {
    margin-top: 20px;
}
