.table-card {
    height: 0;
    padding-bottom: 100%; 
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
    margin: 10px; /* Additional spacing between tables */
}

.input-form::placeholder {
    color: white;
    opacity: 0.7;
}

.login-background {
    position: relative; /* Ensure positioning for the overlay */
    background-image: url('../images/background.jpg'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Hide any overflow */
}

.login-background::before {
    content: ''; /* Required for pseudo-elements */
    position: absolute; /* Positioning for overlay */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay with opacity */
    z-index: 0.5; /* Ensure overlay is above the background but below the content */
}

.table-card .card-body {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.table-card:hover {
    transform: translateY(-10px); /* Lift the card on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
    cursor: pointer;
}

.card-title {
    font-size: 1.2rem;
    font-weight: bold;
}

.card-text {
    font-size: 1rem;
}


/* styles.css */


.receptionist .card {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.receptionist .card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.receptionist .table-card {
    position: relative;
}

.receptionist .hover-bubble {
    position: absolute;
    top: 20%;
    left: 80%;
    transform: translateX(-50%);
    background-color: white;
    color: black;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    width: 250px;
    z-index: 10;
    transition: opacity 0.2s ease-in-out;
}

.receptionist .table-card:hover .hover-bubble {
    opacity: 1;
    visibility: visible;
}

.receptionist .card-body {
    text-align: center;
}

.receptionist h2 {
    font-weight: 700;
    font-size: 2rem;
}

.receptionist .text-dark {
    color: #343a40 !important;
}

.receptionist .text-light {
    color: #f8f9fa !important;
}

.receptionist {
    background-color: #f8f9fa;
}

.table-card {
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    border-radius: 8px;
    border: none;
}

.table-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hover-bubble {
    position: absolute;
    z-index: 10;
    border: 1px solid #ccc;
    max-width: 200px;
}

.card-title {
    font-size: 1.25rem;
}

.alert {
    margin-top: 1rem;
}

.bg-success {
    background-color: #28a745 !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

.bg-warning {
    background-color: #ffc107 !important;
}
